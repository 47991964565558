export function validProgress(progress) {
    if (!progress || progress < 0) {
        return 0;
    }
    else if (progress > 100) {
        return 100;
    }
    return progress;
}
;
